import { ref } from 'vue';

export function useSubscription() {
  const subscription = ref<any>(null);
  // Initialize as true so that before the API call, the state is "loading"
  const isLoading = ref<boolean>(true);
  const error = ref<string | null>(null);
  const client = useStrapiClient();

  async function getActiveSubscription() {
    isLoading.value = true;
    error.value = null;
    try {
      const response: any = await client('/users/me', {
        method: 'GET',
        query: { populate: 'stripe' }
      });
      // Assume the subscription record is under "stripe"
      subscription.value = response?.stripe || null;
    } catch (err: any) {
      console.error('Error fetching subscription:', err);
      error.value = err.message || 'Error fetching subscription.';
    } finally {
      isLoading.value = false;
    }
  }

  return { subscription, isLoading, error, getActiveSubscription };
}
